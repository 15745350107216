import { NextPageContext } from "next";
import { ReactElement, ReactNode } from "react";
import {
  REMOVE_AUTH_DETAILS,
  REMOVE_DETAILS,
  SET_AUTH_DETAILS,
  SET_DETAILS,
} from "@middleware/constants";
import { IOrder } from "../commerce";

export type IType = "ERROR" | "WARNING" | "SUCCESS" | "NONE";

export interface IMessage {
  text: string | null;
  type: IType | null;
}
export interface StateMessaging {
  message: IMessage;
  setMessage: (message: IMessage) => void;
  cleanMessage: () => void;
}
export interface IGlobalStatus {
  message: IMessage;
}
export interface IAppContext {
  Component: ReactElement;
  ctx: NextPageContext;
}
export interface IRedirectOptions {
  ctx: NextPageContext;
  status: number;
}
export type ActionType =
  | {
      type: typeof SET_DETAILS;
      message: IMessage;
    }
  | {
      type: typeof REMOVE_DETAILS;
    };

export interface IAction {
  type?: ActionType;
  payload?: IGlobalStatus;
}

export type ILocal = "fr_FR" | "es";

export interface ILoginIn {
  email: string;
  password: string;
  tokenValue?: string;
}
export interface IAuthResponse {
  token: string;
}
export interface AuthInfo {
  isAuthenticated: boolean | undefined;
  user: IUser | null;
}
export interface StateAuth extends AuthInfo {
  loginByEmail: (user: ILoginIn) => Promise<boolean>;
  loginByGoogle: (user: IGoogleLogin) => Promise<boolean>;
  loginByFacebook: (user: IFacebookLogin) => Promise<boolean>;
  impersonateLogin: (token: string) => Promise<AuthInfo>;
  logoutUser: () => void;
}
export interface IUser {
  id: number;
  username: string;
  token: string;
  roles: string[];
  iat?: number;
  exp?: number;
  last_login?: number;
  isSubscribed: boolean;
  firstName: string;
  lastName: string;
  groupCode: string | null;
}
export interface GroupCode {
  channel: string;
  code: string;
}
export type AuthActionType =
  | {
      type: typeof SET_AUTH_DETAILS;
      data: AuthInfo;
    }
  | {
      type: typeof REMOVE_AUTH_DETAILS;
    };

export interface AuthAction {
  type: AuthActionType;
  payload: AuthInfo;
}
export interface IResponseToken {
  token: string;
  customer: string;
  tokenValue: string | null;
}
export type UserType = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  leadOrigin?: string;
  agreementCheck?: boolean;
};
export interface AxiosRequestConfigData {
  addBearerUser?: boolean;
  customToken?: string | boolean;
}
export interface ICreditTransfer {
  amount: number;
  transferDate: string;
  reason: string;
  comment: string;
}
export interface ISponsorshipCoupon {
  code: string;
}
export enum ESegmentCustomer {
  PROSPECT_0 = "prospect0",
  PROSPECT_6 = "prospect6",
  PROSPECT4_6 = "prospect46",
  PROSPECT_366 = "prospect366",
  PROSPECT_999 = "prospect999",
  ACTIVE_ENGAGED = "activeEngaged",
  ACTIVE_NO_ENGAGED = "activeNotEngaged",
  RETENTION = "retention",
  REACT_9 = "react9",
  REACT_121 = "react121",
  REACT_366 = "react366",
  NONE = "NONE",
}

export enum EGroupCustomer {
  GENERAL = "KITCHEN_DAILY_GENERAL",
  NONE = "NONE",
}
export interface ICustomer {
  id: number;
  defaultAddress: string;
  user: {
    verified: boolean;
    sponsorshipCoupon: ISponsorshipCoupon | null;
    sponsoredUsersCount: number | null;
    maxAllowedSponsorships: number | null;
    creditsBySponsorship: number | null;
    segment: ESegmentCustomer;
  };
  email: string;
  firstName: string;
  lastName: string;
  gender: string;
  fullName: string;
  currentSubscription?: {
    id: number;
    state: ESubscriptionState;
    pastOrderCount: number;
    remainingOrderCount: number;
    hasAnAwaitingPaymentRegularisation: boolean;
    firstUpcomingOrder?: {
      modificationDeadline: string;
    };
    subscriptionType: {
      engagementPeriodNumber: number;
      possibleDeferral: number;
    };
    upcomingOrders?: {
      [key: number]: IOrder;
    };
  } | null;
  totalCreditAmount: number;
  creditExpirationDate: string | null;
  creditTransfers: ICreditTransfer[];
  excludedFoodPreferences: string[];
  ordersCount: number;
  paidOrdersCount: number;
  engaged: boolean;
  firstOrderDate: string;
  mobilePhone: string | null;
  phoneNumber: string | null;
}

export enum ESubscriptionState {
  ON_GOING = "on_going",
  PAUSED = "paused",
  DONE = "done",
  CANCELED = "canceled",
  CLOSED = "closed",
  UNPAID = "unpaid",
}
export enum EDevice {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}
export type IEvaluation = {
  [key: string]: string;
  firstName: string;
  email: string;
  phoneNumber: string;
  familyStatus: string;
};
export type WindowWithDataLayer = Window & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataLayer: Record<string, any>[];
};
export interface IGoogleLogin {
  apiCode: string;
  tokenValue?: string;
}
export interface IFacebookLogin {
  accessToken: string;
  tokenValue?: string;
}

export enum EModalType {
  FIXED = "fixed",
  FLOTTANT = "flottant",
}
export type TAccordionItem = {
  headerTitle: string | ReactNode;
  bodyContent: string | ReactNode;
};

export enum EFrontType {
  STYLE1 = "parat",
  STYLE2 = "parhf",
  STYLE3 = "max18",
  STYLE4 = "parqt",
}
