export const URL_HOME = "/";
export const URL_HOME_TEST = "/home-1";
export const URL_PAGES_CMS = "/cms/";
export const URL_PAGE_PLAT_PREPARE_SOLUTION =
  URL_PAGES_CMS + "plat-prepare-solution-manger-equilibre";
export const URL_PAGE_PLAT_CUISINE_SOLUTION_MALBOUFFE =
  URL_PAGES_CMS + "plat-cuisine-solution-malbouffe";
export const URL_PAGE_PANIERS_REPAS_EVITER_COURSES =
  URL_PAGES_CMS + "paniers-repas-eviter-les-courses";
export const URL_PAGE_PANIERS_RECETTE_CUISINE_FAMILLE =
  URL_PAGES_CMS + "paniers-recette-cuisine-famille";
export const URL_PAGE_PANIER_REPAS_ENVIE_CUISINER =
  URL_PAGES_CMS + "panier-repas-envie-de-cuisiner";
export const URL_PAGE_PANIER_RECETTE_PERFECTIONNER_CUISINE =
  URL_PAGES_CMS + "panier-recette-se-perfectionner-en-cuisine";
export const URL_PAGE_BOX_REPAS_MANGER_EQUILIBRE =
  URL_PAGES_CMS + "box-repas-manger-equilibre";
export const URL_PAGE_CGV = "/cgv";
export const URL_PAGE_CGU = "/cgu";
export const URL_PAGE_CART = "/checkout/cart";
export const URL_PAGE_SUCCESS = "/checkout/success";
export const URL_PAGE_CONFIRMATION = "/confirmation";
export const URL_MEDIA = "/media/image/";
export const URL_PAGE_FAQ = "/faq";
export const URL_PAGE_HISTOIRE = "/notre-histoire";
export const URL_PAGE_MANGER = "/manger-equilibre";
export const URL_PAGE_FORMULES = "/formules";
export const URL_PAGE_FORMULES_2 = "/formules";
export const URL_PAGE_FORMULES_PLUS = "/formules-plus";
export const URL_PAGE_FORMULES_PLUS_2 = "/formules-plus-2";
export const URL_PAGE_COOKIES = "/cookies";
export const URL_PAGE_CONTACT = "/contacts";
export const URL_PAGE_ENTREPRISE = "/livraison-repas-bureau-entreprise";
export const URL_PAGE_FORMULES_MEALS = "/formules-repas";
export const URL_PAGE_FORMULES_RECETTES = "/formules-recettes";
export const URL_PROGRAMME_DAILY = "/programme-daily/[code]";
export const URL_PROGRAMME_GOURMANDISE = "/programme-gourmandises";
export const URL_PARRAINAGE = "/parrainage";
export const URL_APP_IOS = "/ios-lien";
export const URL_APP_ANDROID = "/android-lien";
export const URL_PAGE_CUSTOMER_REGISTER = "/account/register";
export const URL_PAGE_CUSTOMER_ORDER = "/account/shippings/upcoming";
export const URL_PAGE_CUSTOMER_ORDER_PAST = "/account/shippings/past";
export const URL_PAGE_CUSTOMER_SUBSCRIPTION = "/account/subscription";
export const URL_PAGE_CUSTOMER_INFORMATION = "/account/informations";
export const URL_PAGE_CUSTOMER_UPDATE_PASSWORD = "/account/update-password";
export const URL_PAGE_CUSTOMER_LOYALTY = "/account/loyalty";
export const URL_PAGE_CUSTOMER_LOYALTY_POINTS = "/account/loyalty-points";
export const URL_PAGE_LOYALTY_SUBMENU_MOBILE = "/account/creditMobileSubMenu";
export const URL_PAGE_ACCOUNT_SUBMENU_MOBILE = "/account/accountSubMenu";
export const URL_PAGE_SHIPMENT_DETAILS = "/account/shippings/details/";
export const URL_PAGE_SHIPMENT_EDIT = "/account/shippings/edit/";
export const URL_PAGE_DAILY = "/programme-daily/"; // dynamic url issue vercel/next.js#43769
export const URL_PAGE_EDIT_MEALS = URL_PAGE_SHIPMENT_EDIT + "select-meal/"; // dynamic url issue vercel/next.js#43769
export const URL_PAGE_EDIT_EXTRA = URL_PAGE_SHIPMENT_EDIT + "select-extra/"; // dynamic url issue vercel/next.js#43769
export const URL_PAGE_EDIT_ADDRESS = URL_PAGE_SHIPMENT_EDIT + "address/"; // dynamic url issue vercel/next.js#43769
export const URL_PAGE_EDIT_PAYMENT = URL_PAGE_SHIPMENT_EDIT + "payment/"; // dynamic url issue vercel/next.js#43769
export const URL_PAGE_EDIT_BUNDLE = URL_PAGE_SHIPMENT_EDIT + "select-bundle/"; // dynamic url issue vercel/next.js#43769
